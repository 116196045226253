import React from 'react';
import { Link } from 'gatsby';

import Layout from '../../components/layout';
import Seo from '../../components/Seo';

const InteriorPage = ({ location }) => (
  <Layout location={location}>
    <div className="page-wrapper-inner">
      <Seo
        title="Services"
        slug={location.pathname}
        description="Rosewood Family Advisors LLP provides an integrated suite of services customized to fit the needs of high net worth individuals."
      />
      {/* Main */}
      <section className="wrapper style1">
        <div className="container">
          <div className="row gtr-200">
            <div className="col-4 col-12-narrower">
              <div id="sidebar">

                {/* Sidebar */}

                <section>
                  <h3>Services</h3>
                  <ul className="links">
                    <li><Link to="/services/">Overview</Link></li>
                    <li><Link to="/services/bill-pay-and-accounting/">Bill Pay and Accounting</Link></li>
                    <li><Link to="/services/income-tax-planning-and-compliance/">Income Tax Planning and Compliance</Link></li>
                    <li><Link to="/services/family-administrative-services/">Family Administrative Services</Link></li>
                    <li><Link to="/services/estate-and-gift-planning/">Estate and Gift Planning</Link></li>
                    <li><Link to="/services/philanthropic-consulting/">Philanthropic Consulting</Link></li>
                  </ul>
                </section>

              </div>
            </div>
            <div className="col-8 col-12-narrower imp-narrower">
              <div id="content">

                {/* Content */}
                <article>
                  <header>
                    <h2>Services</h2>
                    <p>
                      Rosewood Family Advisors LLP provides an integrated suite of services customized to fit the needs of high net worth individuals.
                    </p>
                  </header>

                  <p>
                    From tax planning to estate management, philanthropic advising to lifestyle management, our team of professionals will help you meet your financial goals.
                  </p>


                  <h3><Link to="/services/bill-pay-and-accounting/">Bill Pay Services</Link></h3>

                  <ul>
                    <li>Bill payment</li>
                    <li>Bookkeeping</li>
                    <li>Reporting</li>
                    <li>Cash flow planning</li>
                    <li>Family banking borrowing</li>
                    <li>Risk management and insurance</li>
                    <li>Corporate accounting</li>
                    <li>Trust accounting</li>
                    <li>Partnership accounting</li>
                  </ul>


                  <h3><Link to="/services/income-tax-planning-and-compliance/">Income Tax Planning and Compliance</Link></h3>

                  <ul>
                    <li>Income tax planning</li>
                    <li>Implementation of tax strategies</li>
                    <li>Tax return preparation</li>
                    <li>Managing taxing authority audits</li>
                  </ul>

                  <h3><Link to="/services/family-administrative-services/">Family Administrative Services</Link></h3>

                  <ul>
                    <li>Coordination of in-house services and related activities</li>
                    <li>Management of major assets: real estate, artwork, aircraft, yacht, etc.</li>
                    <li>Administrative support</li>
                    <li>Family meeting coordination</li>
                    <li>Family education</li>
                  </ul>

                  <h3><Link to="/services/estate-and-gift-planning/">Estate and Gift Planning</Link></h3>

                  <ul>
                    <li>Understand goals</li>
                    <li>Review existing plans</li>
                    <li>Develop strategies</li>
                    <li>Quarterback implementation</li>
                    <li>Asset protection considerations</li>
                    <li>Trust planning</li>
                    <li>Periodic updates</li>
                  </ul>

                  <h3><Link to="/services/philanthropic-consulting/">Philanthropic Consulting</Link></h3>

                  <ul>
                    <li>Foundation administration</li>
                    <li>Coordinate resources for grant making</li>
                    <li>Investment strategy and monitoring</li>
                    <li>Tax and information return preparation</li>
                  </ul>

                </article>

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default InteriorPage;
